import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import UmbracoImage from 'common/Image/UmbracoImage';

import { VideoProductsGalleryItemProps } from './models';

import './VideoProductsGalleryItem.scss';

const VideoProductsGalleryItem: FC<VideoProductsGalleryItemProps> = ({
  productHeader,
  productImage,
  productImageAlt,
  productButton,
}) => {
  const linkReferralProps = {
    ...(productButton[0].link?.[0]?.target && { target: '_blank', rel: 'noreferrer noopener' }),
  };

  return (
    <div className="video-gallery__list-item-wrapper">
      <div className="video-gallery__list-item">
        <div className="video-gallery__list-image-wrapper" data-testid="video-product__image">
          <UmbracoImage
            image={productImage}
            alt={productImageAlt}
            className="video-gallery__list-image"
            objectFit="contain"
          />
        </div>

        <DangerouslySetInnerHtml html={productHeader} className="video-gallery__list-header" />

        <a
          className="btn video-gallery__list-link"
          aria-label={productButton[0].ariaLabel}
          href={productButton[0].link[0].url}
          {...linkReferralProps}
        >
          {productButton[0].text}
        </a>
      </div>
    </div>
  );
};

export default VideoProductsGalleryItem;

// eslint-disable-next-line import/prefer-default-export
export const CAROUSEL_VIDEO_PAGE_SETTINGS = {
  dots: true,
  arrows: true,
  autoplay: false,
  infinite: false,
  speed: 400,
  slidesToScroll: 1,
  swipeToSlide: true,
  accessibility: true,
  variableWidth: true,
};

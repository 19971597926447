import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { VideoContentHeaderType } from './models';

import './videoContentHeader.scss';

const VideoContentHeader: FC<VideoContentHeaderType> = ({ videoTemplateHeader }) => (
  <div className="video-content">
    <div className="container">
      <DangerouslySetInnerHtml html={videoTemplateHeader} className="video-content__header" />
    </div>
  </div>
);

export default VideoContentHeader;

import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import VideoProductsGallery from './VideoProductsGallery';
import { VideoProductsType } from './models';

import './videoProducts.scss';

const VideoProducts: FC<VideoProductsType> = ({ productsHeader, sectionId, productsData }) => (
  <section className="video-products" id={sectionId}>
    <div className="container">
      <DangerouslySetInnerHtml html={productsHeader} className="video-products__header" />

      {productsData?.length ? (
        <div className="video-products__gallery">
          {productsData.map(({ header, productGallery }) => (
            <VideoProductsGallery key={header} header={header} productGallery={productGallery} />
          ))}
        </div>
      ) : null}
    </div>
  </section>
);

export default VideoProducts;

import React, { FC } from 'react';
import NiceCard from 'components/NiceCard';
import ButtonPosition from 'components/NiceCard/CardButtonPositionEnum';
import { VideoListItemProps } from './models';

const VideoListItem: FC<VideoListItemProps> = ({
  title,
  description,
  videoImageAlt,
  videoCoverImage,
  youtubeVideo,
  playVideoText,
  handleModalOpen,
}) => {
  const topCardContent = {
    cardImage: { imageAlt: videoImageAlt, image: videoCoverImage },
    isVideoOverlay: true,
  };
  const bottomCardContent = {
    title,
    description,
    descriptionClassname: 'video-list__card--description',
    titleClassname: 'video-list__card--title',
    buttons: [
      {
        text: playVideoText,
        ariaLabel: playVideoText,
        onClick: () =>
          handleModalOpen({
            title,
            description,
            videoImageAlt,
            videoCoverImage,
            youtubeVideo,
          }),
        position: ButtonPosition.Left,
        variant: { reversed: true },
      },
    ],
  };

  return <NiceCard key={title} {...{ topCardContent, bottomCardContent }} />;
};

export default VideoListItem;

import React, { FC, useCallback } from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { VideoLinksType } from './models';

import './VideoLinks.scss';

const VideoLinks: FC<VideoLinksType> = ({ seoTitle, linkList }) => {
  const handleScroll = useCallback(
    (queryString: string | undefined) => () => {
      queryString && scrollTo(queryString);
    },
    []
  );

  return (
    <section className="video-links container">
      <h2 className="video-links__title sr-only">{seoTitle}</h2>

      {linkList?.length ? (
        <ul className="video-links__list">
          {linkList.map((linkItem) => (
            <li className="video-links__list-item" key={linkItem.link.name}>
              <button
                className="btn video-links__link"
                type="button"
                role="link"
                onClick={handleScroll(linkItem.link.queryString)}
                aria-label={linkItem.linkAriaLabel}
              >
                {linkItem.link.name}
              </button>
            </li>
          ))}
        </ul>
      ) : null}
    </section>
  );
};

export default VideoLinks;

import React, { FC } from 'react';
import Slider from 'react-slick';

import { useScreenRecognition } from 'utils/hooks';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { CAROUSEL_VIDEO_PAGE_SETTINGS } from '../constants';
import VideoProductsGalleryItem from '../VideoProductsGalleryItem';
import { VideoProductsGalleryProps } from './models';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './videoProductsGallery.scss';

const VideoProductsGallery: FC<VideoProductsGalleryProps> = ({ header, productGallery }) => {
  const { isXl } = useScreenRecognition();

  return (
    <div className="video-gallery">
      <DangerouslySetInnerHtml html={header} className="video-gallery__header" />

      {productGallery?.length ? (
        <div className="video-gallery__list">
          <Slider {...CAROUSEL_VIDEO_PAGE_SETTINGS} slidesToShow={isXl ? 3 : 1}>
            {productGallery.map(
              ({ productHeader, productImage, productButton, productImageAlt }) => (
                <VideoProductsGalleryItem
                  key={productImageAlt}
                  productHeader={productHeader}
                  productImage={productImage}
                  productImageAlt={productImageAlt}
                  productButton={productButton}
                />
              )
            )}
          </Slider>
        </div>
      ) : null}
    </div>
  );
};

export default VideoProductsGallery;

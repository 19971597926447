import React, { FC } from 'react';
import { graphql } from 'gatsby';

import VideoContentHeader from 'components/VideoContentHeader';
import VideoLinks from 'components/VideoLinks';
import VideoProduct from 'components/VideoProduct';
import Videos from 'components/Videos';
import VideoProducts from 'components/VideoProducts';
import Layout from 'layout/Layout';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';

import './videoPage.scss';

const VideoTemplatePage: FC<VideoTemplateTypes.VideoTemplateProps> = ({
  data: {
    allUmbracoVideoTemplate: { nodes: umbracoVideoTemplate },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    header,
    links,
    product,
    videos,
    products,
  } = umbracoVideoTemplate[0];

  return (
    <Layout langProps={{ urls, lang }} className="video-page" isVideoHeader isWithoutFooter>
      <Seo
        title={seoMetaTitle}
        description={seoMetaDescription}
        keywords={seoMetaKeywords}
        externalHreflangs={seoExternalHreflangs}
        ogImage={ogImage}
        lang={lang}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {header?.length ? (
        <VideoContentHeader videoTemplateHeader={header[0].videoTemplateHeader} />
      ) : null}
      {links?.length ? (
        <VideoLinks seoTitle={links[0].seoTitle} linkList={links[0].linkList} />
      ) : null}
      {product?.length ? (
        <VideoProduct
          header={product[0].header}
          sectionId={product[0].sectionId}
          image={product[0].image}
          imageAlt={product[0].imageAlt}
          additionalDescription={product[0].additionalDescription}
          backgroundImage={product[0].backgroundImage}
          backgroundImageAlt={product[0].backgroundImageAlt}
        />
      ) : null}
      {videos?.length ? (
        <Videos
          header={videos[0].header}
          sectionId={videos[0].sectionId}
          playVideoText={videos[0].playVideoText}
          nextTitle={videos[0].nextTitle}
          closeButtonText={videos[0].closeButtonText}
          buttonText={videos[0].buttonText}
          videoListSorted={videos[0].videoListSorted}
        />
      ) : null}
      {products?.length ? (
        <VideoProducts
          productsHeader={products[0].productsHeader}
          sectionId={products[0].sectionId}
          productsData={products[0].productsData}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query VideoTemplateQuery($pageId: String) {
    allUmbracoVideoTemplate(filter: { id: { eq: $pageId } }) {
      nodes {
        header {
          videoTemplateHeader
        }
        links {
          seoTitle
          linkList {
            linkAriaLabel
            link {
              name
              queryString
            }
          }
        }
        product {
          header
          sectionId
          additionalDescription
          image {
            ...umbracoImage
          }
          imageAlt
          backgroundImage {
            ...umbracoImage
          }
          backgroundImageAlt
        }
        videos {
          header
          sectionId
          nextTitle
          closeButtonText
          buttonText
          playVideoText
          videoListSorted {
            videoListItem {
              title
              description
              videoImageAlt
              youtubeVideo
              videoCoverImage {
                ...umbracoImage
              }
            }
          }
        }
        products {
          productsHeader
          sectionId
          productsData {
            header
            productGallery {
              productHeader
              productImageAlt
              productImage {
                ...umbracoImage
              }
              productButton {
                ariaLabel
                text
                withReverse
                link {
                  name
                  target
                  url
                }
              }
            }
          }
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
      }
    }
  }
`;

export default VideoTemplatePage;

import React, { FC, useCallback, useState } from 'react';
import { Modal } from '@phx-husky/modal';

import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import VideoListItem from 'components/VideoList/VideoListItem';

import { INITIAL_MODAL_CONTENT } from './constants';
import VideoWithThumbnail from '../VideoNaturalBanner/VideoWithThumbnail/VideoWithThumbnail';
import { VideosType } from './models';

import './videos.scss';

const Videos: FC<VideosType> = ({
  header,
  sectionId,
  playVideoText,
  nextTitle,
  closeButtonText,
  buttonText,
  videoListSorted,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(INITIAL_MODAL_CONTENT);
  const [isVisibleVideo, setIsVisibleVideo] = useState(false);
  const [visibleAmount, setVisibleAmount] = useState(3);

  const close = useCallback(() => setShowModal(false), []);
  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const loadMore = () => {
    setVisibleAmount(visibleAmount + 5);
  };

  const sortedList = videoListSorted.map((video) => video.videoListItem[0]);

  return (
    <section className="videos" id={sectionId}>
      <div className="container">
        <DangerouslySetInnerHtml className="videos__header" html={header} />
        {sortedList?.length ? (
          <div className="videos__list">
            {sortedList
              .slice(0, visibleAmount)
              .map(
                ({ title, description, videoImageAlt, videoCoverImage, youtubeVideo }, index) => (
                  <div key={title}>
                    {index === 1 && nextTitle ? (
                      <h3 className="videos__list-title">{nextTitle}</h3>
                    ) : null}
                    <VideoListItem
                      title={title}
                      description={description}
                      videoImageAlt={videoImageAlt}
                      videoCoverImage={videoCoverImage}
                      youtubeVideo={youtubeVideo}
                      playVideoText={playVideoText}
                      handleModalOpen={handleModalOpen}
                    />
                  </div>
                )
              )}
          </div>
        ) : null}
        {visibleAmount < sortedList.length ? (
          <button type="button" className="btn videos__load-button" onClick={loadMore}>
            {buttonText}
          </button>
        ) : null}
        <Modal isShowing={showModal} close={close}>
          <div className="modal__content">
            <Button
              className="modal__content--button-close"
              onClick={close}
              ariaLabel={closeButtonText}
            />
            <VideoWithThumbnail
              className="card__top--video-overlay"
              image={modalContent.videoCoverImage}
              setIsVisibleVideo={setIsVisibleVideo}
              isVisibleVideo={isVisibleVideo}
              imageAsBackgroundAlt={modalContent.videoImageAlt}
              bannerVariant="video"
              src={modalContent.youtubeVideo}
              title={modalContent.title}
              autoplay
              isPlayButton={false}
            />
            <div className="video-list__card--title">
              <h3>{modalContent.title}</h3>
            </div>
            <div className="video-list__card--description">
              <p>{modalContent.description}</p>
            </div>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default Videos;

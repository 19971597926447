import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import UmbracoImage from 'common/Image/UmbracoImage';

import { VideoProductType } from './models';

import './videoProduct.scss';

const VideoProduct: FC<VideoProductType> = ({
  header,
  sectionId,
  image,
  imageAlt,
  additionalDescription,
  backgroundImage,
  backgroundImageAlt,
}) => (
  <section className="video-product" id={sectionId}>
    <div className="container">
      <DangerouslySetInnerHtml html={header} className="video-product__header" />
      <div className="video-product__image-wrapper" data-testid="video-product__image">
        <UmbracoImage
          image={backgroundImage}
          alt={backgroundImageAlt}
          className="video-product__image-background"
        />
        <UmbracoImage image={image} alt={imageAlt} className="video-product__image" />
      </div>
      <DangerouslySetInnerHtml
        html={additionalDescription}
        className="video-product__sub-description"
      />
    </div>
  </section>
);

export default VideoProduct;
